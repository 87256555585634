import { Box } from "@material-ui/core";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { Edit } from "@material-ui/icons";
import DonutLargeIcon from "@material-ui/icons/DonutLarge";
import PersonIcon from "@material-ui/icons/Person";
import { Button, PermissionGate } from "components";
import { ChipStatus } from "components/ChipStatus";
import { useRef, useState } from "react";
import { AiFillLock } from "react-icons/ai";
import { BiTransfer } from "react-icons/bi";

import { BatchBlockPopup } from "../components/batchBlockPopup";
import { BatchReceivedPopup } from "../components/batchReceivedPopup";
import { BatchUnblockPopup } from "../components/batchUnblockPopup";
import { CustomDivider } from "../components/CustomDivider";
import { RevertWastePopup } from "../components/RevertWastepopup";
import { WastePopup } from "../components/WastePopup";
import { Migration } from "../Migration";
import {
  CardDateTime,
  CardTitle,
  CardValue,
  ContainerWithIcon,
  ContentWithIcon,
  DetailCardContainer,
  DetailCardContent,
  DetailContainer,
  DetailContent,
  InfoIconContent,
  InfoId,
  InfoIdContent,
  InfosContainer,
  PaperInfoContainer,
  PaperInfoContent,
  PaperInfoTitle,
  PaperInfoValue,
  ProductInfoContent,
  TitleDetail,
  useStyles
} from "../style";

export const Detail = ({
  batchData,
  refresh,
  batchReasons,
  isLoadingRefresh
}) => {
  const migrationRef = useRef(null);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [batchReceivedData, setBatchReceivedData] = useState({
    open: false,
    data: {}
  });
  const [batchBlockData, setBatchBlockData] = useState({
    open: false,
    data: {}
  });
  const [batchUnblockData, setBatchUnblockData] = useState({
    open: false,
    data: {}
  });
  const [openWastePopup, setOpenWastePopup] = useState(false);
  const [openRevertWastePopup, setOpenRevertWastePopup] = useState(false);
  const classes = useStyles();

  const handleOpenEditBatchPopup = () => {
    setBatchReceivedData({ open: true, data: batchData });
  };

  const handleOpenBlockBatchPopup = () => {
    setBatchBlockData({ open: true, data: batchData });
  };

  const handleOpenUnblockBatchPopup = () => {
    setBatchUnblockData({ open: true, data: batchData });
  };

  const handleMenuOpen = event => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const handleOpenWastePopup = () => {
    setOpenWastePopup(true);
  };

  const isNotReceipted = batchData.status !== "Recebido";
  const isNotConfirmed = batchData?.purchaseItemId === 9;
  const canEditBatch = !!isNotReceipted || !!isNotConfirmed;
  const canEditBatchMessage = isNotReceipted ? "recebido" : "confirmado";

  return (
    <>
      <Box display="flex" justifyContent="space-between">
        <TitleDetail>Dados do lote</TitleDetail>
        <>
          <Box
            display="flex"
            justifyContent="flex-end"
            style={{ gap: "1rem" }}
            className={classes.responsiveButtons}
          >
            {canEditBatch && (
              <>
                <span
                  style={{
                    marginTop: "-2rem",
                    marginRight: "-13.5rem",
                    color: "#EB001B",
                    height: "fit-content",
                    borderRadius: "5px",
                    fontWeight: "bolder",
                    fontSize: "1rem"
                  }}
                >
                  Lote não {canEditBatchMessage}, não é possível interagir.
                </span>
              </>
            )}
            <PermissionGate permissions={["Tech", "Admin", "BatchMigration"]}>
              <Button
                variant="contained"
                onClick={() => migrationRef.current?.handleOpen()}
                startIcon={<BiTransfer />}
                disabled={canEditBatch}
              >
                Migrar
              </Button>
              {/* <Button
                variant="contained"
                onClick={handleOpenWastePopup}
                startIcon={<BiTransfer />}
                disabled={canEditBatch}
              >
                Movimentar
              </Button> */}
              <Button
                variant="outlined"
                onClick={handleMenuOpen}
                startIcon={<Edit />}
                disabled={canEditBatch}
              >
                Ações
              </Button>
              <Menu
                anchorEl={menuAnchorEl}
                open={Boolean(menuAnchorEl)}
                onClose={handleMenuClose}
              >
                <MenuItem onClick={handleOpenEditBatchPopup}>
                  Qnt. recebida
                </MenuItem>
                <MenuItem onClick={handleOpenBlockBatchPopup}>
                  Bloquear
                </MenuItem>
                <MenuItem onClick={handleOpenUnblockBatchPopup}>
                  Desbloquear
                </MenuItem>
                <MenuItem onClick={() => setOpenRevertWastePopup(true)}>
                  Reverter waste
                </MenuItem>
              </Menu>
            </PermissionGate>
          </Box>
        </>
      </Box>

      <DetailContainer>
        <DetailContent>
          <Box display="flex" alignItems="center" style={{ gap: "1rem" }}>
            <InfoIdContent elevation={3}>
              ID: <InfoId>{batchData.id}</InfoId>
            </InfoIdContent>
            {batchData.blockQuantity > 0 && (
              <ChipStatus
                statusId={4}
                label="Lote Bloqueado"
                icon={<AiFillLock color="#bf2222" />}
              />
            )}
          </Box>
          <DetailCardContainer>
            <InfosContainer>
              <ProductInfoContent elevation={3}>
                <DetailCardContent>
                  <CardTitle>Produto</CardTitle>
                  <CardValue>
                    {batchData.productId} - {batchData.product}
                  </CardValue>
                </DetailCardContent>
                <CustomDivider />
                <DetailCardContent>
                  <CardTitle>Seller</CardTitle>
                  <CardValue>{batchData.seller}</CardValue>
                </DetailCardContent>
              </ProductInfoContent>
            </InfosContainer>
            <ContainerWithIcon elevation={3}>
              <InfoIconContent>
                <DonutLargeIcon />
              </InfoIconContent>
              <ContentWithIcon>
                <DetailCardContent>
                  <CardTitle>Estágio</CardTitle>
                  <CardValue>{batchData.stage}</CardValue>
                </DetailCardContent>
                <CustomDivider />
                <DetailCardContent>
                  <CardTitle>Status</CardTitle>
                  <CardValue>{batchData.status}</CardValue>
                </DetailCardContent>
                <CustomDivider />
                <DetailCardContent>
                  <CardTitle>Classificação</CardTitle>
                  <CardValue>{batchData.classification}</CardValue>
                </DetailCardContent>
              </ContentWithIcon>
            </ContainerWithIcon>
          </DetailCardContainer>
        </DetailContent>
        <DetailContent>
          <DetailCardContainer>
            <ContainerWithIcon elevation={3}>
              <InfoIconContent>
                <PersonIcon />
              </InfoIconContent>
              <ContentWithIcon>
                <DetailCardContent>
                  <CardTitle>Criado por</CardTitle>
                  <CardDateTime>{batchData.createdDateTime}</CardDateTime>
                  <CardValue>{batchData.createdBy}</CardValue>
                </DetailCardContent>

                <CustomDivider />

                <DetailCardContent>
                  <CardTitle>Entrada</CardTitle>
                  <CardDateTime>{batchData?.receiptDateTime}</CardDateTime>
                  <CardValue>{batchData?.receiptBy}</CardValue>
                </DetailCardContent>

                <CustomDivider />

                <DetailCardContent>
                  <CardTitle>Editado por</CardTitle>
                  <CardDateTime>{batchData.updatedDateTime}</CardDateTime>
                  <CardValue>{batchData.updatedBy}</CardValue>
                </DetailCardContent>

                <CustomDivider />

                <DetailCardContent>
                  <CardTitle>Blitz</CardTitle>
                  <CardDateTime>{batchData?.blitzDateTime}</CardDateTime>
                  <CardValue>{batchData?.blitzBy}</CardValue>
                </DetailCardContent>

                <CustomDivider />

                <DetailCardContent>
                  <CardTitle>Descarregamento</CardTitle>
                  <CardDateTime>{batchData?.unloadingDateTime}</CardDateTime>
                  <CardValue>{batchData?.unloadingBy}</CardValue>
                </DetailCardContent>
              </ContentWithIcon>
            </ContainerWithIcon>
          </DetailCardContainer>
        </DetailContent>
        <DetailContent>
          <PaperInfoContainer elevation={3}>
            <PaperInfoContent>
              <PaperInfoTitle>Quantidade</PaperInfoTitle>
              <PaperInfoValue>{batchData.quantity}</PaperInfoValue>
            </PaperInfoContent>
            <PaperInfoContent>
              <PaperInfoTitle>Qtd. Prevista</PaperInfoTitle>
              <PaperInfoValue>{batchData.quantityForecast}</PaperInfoValue>
            </PaperInfoContent>
            <PaperInfoContent>
              <PaperInfoTitle>Qtd. Caixas</PaperInfoTitle>
              <PaperInfoValue>{batchData.boxQuantity ?? "--"}</PaperInfoValue>
            </PaperInfoContent>
            <PaperInfoContent>
              <PaperInfoTitle>Qtd. Amostra</PaperInfoTitle>
              <PaperInfoValue>
                {batchData.sampleQuantity ?? "--"}
              </PaperInfoValue>
            </PaperInfoContent>
            <PaperInfoContent>
              <PaperInfoTitle>Qtd. Abaixo qualidade</PaperInfoTitle>
              <PaperInfoValue>
                {batchData.sampleLowQuantity ?? "--"}
              </PaperInfoValue>
            </PaperInfoContent>
            <PaperInfoContent>
              <PaperInfoTitle>Qtd. não classificada</PaperInfoTitle>
              <PaperInfoValue>
                {batchData.formattedUnclassifiedQty ?? "--"}
              </PaperInfoValue>
            </PaperInfoContent>
            <PaperInfoContent>
              <PaperInfoTitle>Qtd. disponível</PaperInfoTitle>
              <PaperInfoValue>
                {batchData.availableQuantity ?? "--"}
              </PaperInfoValue>
            </PaperInfoContent>
            <PaperInfoContent>
              <PaperInfoTitle>Peso médio un.</PaperInfoTitle>
              <PaperInfoValue>{batchData.averageUnitWeight}</PaperInfoValue>
            </PaperInfoContent>
            <PaperInfoContent>
              <PaperInfoTitle>Shelf life</PaperInfoTitle>
              <PaperInfoValue>{batchData.shelfLife}</PaperInfoValue>
            </PaperInfoContent>
            <PaperInfoContent>
              <PaperInfoTitle>Percentual de Recusa</PaperInfoTitle>
              <PaperInfoValue>{batchData?.refusalPercentage}</PaperInfoValue>
            </PaperInfoContent>
          </PaperInfoContainer>
        </DetailContent>
      </DetailContainer>
      <Migration refresh={refresh} batch={batchData} ref={migrationRef} />
      <BatchReceivedPopup
        data={batchReceivedData.data}
        open={batchReceivedData.open}
        handleClose={() => setBatchReceivedData({ open: false, data: {} })}
        refresh={refresh}
      />
      <BatchBlockPopup
        data={batchBlockData.data}
        open={batchBlockData.open}
        handleClose={() => setBatchBlockData({ open: false, data: {} })}
        refresh={refresh}
      />
      <BatchUnblockPopup
        data={batchUnblockData.data}
        open={batchUnblockData.open}
        handleClose={() => setBatchUnblockData({ open: false, data: {} })}
        refresh={refresh}
      />
      <WastePopup
        open={openWastePopup}
        handleClose={() => setOpenWastePopup(false)}
        batchData={batchData}
        batchReasons={batchReasons}
        refresh={refresh}
      />
      <RevertWastePopup
        open={openRevertWastePopup}
        handleClose={() => setOpenRevertWastePopup(false)}
        handleNewWaste={handleOpenWastePopup}
        batchId={batchData.id}
        refresh={refresh}
        isLoadingRefresh={isLoadingRefresh}
      />
    </>
  );
};
